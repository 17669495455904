import React from "react";

const SimpleSelect = ({ label }) => {
  return (
    <select className="bg-slate-50 dark:bg-slate-800 text-slate-600 dark:text-slate-200 px-5 py-2 rounded-full min-w-[160px] border-r-[16px] border-solid border-transparent overflow-hidden bg-[url('./extras/imgs/arrow-down.svg')] bg-no-repeat bg-right outline-none cursor-pointer focus:bg-slate-100">
      <option value="0" selected disabled>
        {label}
      </option>
      <option value="1">Item 2</option>
      <option value="2">Item 3</option>
      <option value="3">Item 4</option>
    </select>
  );
};

export default SimpleSelect;
