// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-slider {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #3774ff;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
    background-color: grey;
}

.example-track {
    position: relative;
    background: #ddd;
}

.example-track.example-track-0 {
    background: #83a9ff;
}

.horizontal-slider .example-track {
    top: -10px;
    height: 4px;
}

.horizontal-slider .example-thumb {
    top: -13px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/pages/logged/newEquipment/steps/deps/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".horizontal-slider {\n    width: 100%;\n    max-width: 500px;\n    margin: auto;\n}\n\n.example-thumb {\n    cursor: pointer;\n    position: absolute;\n    z-index: 100;\n    background: #ffffff;\n    border: 5px solid #3774ff;\n    border-radius: 100%;\n    display: block;\n    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);\n}\n\n.example-thumb.active {\n    background-color: grey;\n}\n\n.example-track {\n    position: relative;\n    background: #ddd;\n}\n\n.example-track.example-track-0 {\n    background: #83a9ff;\n}\n\n.horizontal-slider .example-track {\n    top: -10px;\n    height: 4px;\n}\n\n.horizontal-slider .example-thumb {\n    top: -13px;\n    width: 10px;\n    outline: none;\n    height: 10px;\n    line-height: 38px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
