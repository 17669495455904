import React, { useState } from "react";
import Spinner from "../layout/utils/Spinner";
import { FileWarning } from "lucide-react";
import Modal from "../modal/Modal";

const variants = {
  none:
    "",
  normal:
    "disabled:opacity-75 bg-[#002772] dark:bg-[#06b0b4] text-white h-10 px-5 rounded-full text-sm font-medium hover:opacity-90",
  transparent:
    "disabled:opacity-75 bg-transparent hover:bg-[#002772] hover:text-white text-[#002772] dark:hover:bg-[#06b0b4] dark:text-white h-10 px-5 rounded-full text-sm font-medium",
};

const Button = ({ children, variant, loading, disabled, requireConfirmation, confirmation = {}, ...props }) => {

  const {
    confirmColor = "red",
    textConfirm = "Você tem certeza que gostaria de fazer isso?",
    titleConfirm = "Atenção!",
    onConfirm,
  } = confirmation;

  const [modalConfirm, setModalConfirm] = useState(false);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setModalConfirm(false);
  };

  return (
    <>
      <button {...props}
        className={variants[variant]}
        onClick={() => {
          if (requireConfirmation) {
            setModalConfirm(true);
          } else if (props.onClick) {
            props.onClick();
          }
        }} disabled={(disabled || loading)}>
        {loading ? <Spinner /> : children}
      </button>
      {requireConfirmation &&
        <Modal show={modalConfirm} onClose={() => setModalConfirm(false)} maxWidth="sm">
          <div className="p-3 text-center">
            <div className="flex justify-center mb-4">
              <div className={`rounded-full bg-${confirmColor}-100 dark:bg-custom-500/20 p-3`}>
                <FileWarning className={`text-${confirmColor}-500`} />
              </div>
            </div>
            <h2 className="text-lg font-medium text-gray-900 dark:text-white" dangerouslySetInnerHTML={{ __html: titleConfirm }}>
            </h2>
            <p className="mt-1 text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: textConfirm }}>
            </p>
            <div className="mt-6 flex justify-center gap-2">
              <Button variant="transparent" onClick={() => { setModalConfirm(false) }}>Cancelar</Button>
              <Button variant="normal" color={confirmColor} disabled={(loading) ? loading : false} onClick={() => handleConfirm()}>Sim, confirmar</Button>
            </div>
          </div>
        </Modal>}
    </>);
};

Button.defaultProps = {
  children: "Button title",
  variant: "normal",
};

export default Button;
