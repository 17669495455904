import React, { useState } from "react";

import { Content } from "../../../components/layout";

import { ChevronDown, ChevronUp, Database, School } from "lucide-react";

// img
import Estoque from "../../../extras/imgs/estoque.png";
import moment from "moment/moment";
import 'moment/locale/pt-br'
import YouTube from "react-youtube";
import { useVideosTutoriais } from "../../../extras/services/queries";
import { useEffect } from "react";
import { useRef } from "react";
import { extrairIdVideo } from "../../../extras/utils/utils";
moment.locale('pt-br')
const Videos = () => {
    const [opened, setOpened] = useState(0);
    const { data } = useVideosTutoriais();
    const player = useRef(null);

    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
            autoplay: 0,
        },
    };

    const videos = data?.data || [];

    const onPlayerReady = (event) => {
        player.current = event.target;

        if (data?.data)
            event.target.loadVideoById(extrairIdVideo(data?.data[0]?.videos[0]?.path));
    };

    const changePlayer = (url) => {
        const videoID = extrairIdVideo(url);

        player.current.loadVideoById(videoID);
        player.current.stopVideo();
    }

    return (
        <Content title="Lista de conteúdos" icon={<School />}>
            <div className="flex flex-col md:flex-row p-5 gap-2 md:gap-10 md:items-center">
                <div>
                    <img src={Estoque} alt="Imagem de referência de estoque na página" />
                </div>
                <div className="flex flex-col gap-3 md:self-end pb-2">
                    <h2 className="font-bold text-[#002772] dark:text-[#06B0B4]">
                        O que posso fazer aqui?
                    </h2>
                    <p className="max-w-[600px] text-slate-600 dark:text-white">
                        Fique por dentro de todas funcionalidades da nossa plataforma!
                    </p>
                </div>
            </div>

            <div className="px-5 mb-5 w-full">
                <div className="bg-white dark:bg-slate-900 shadow-sm rounded-md px-5 py-4 min-w-[100vh]">
                    <div className="grid grid-cols-3">
                        <div className="col-span-2">
                            <YouTube id="ytb" videoId={null} opts={opts} onReady={onPlayerReady} />
                        </div>
                        <div className="bg-[#CCC]/10 rounded max-h-[390px] overflow-auto">
                            {/* <div className="text-center font-bold mb-2 lg:mb-0 text-slate-700 dark:text-white">Lista de conteúdo</div> */}
                            {videos?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className={`flex justify-between cursor-pointer items-center ${opened != index && 'bg-[#CCC]/20'} p-4 border-b`} onClick={() => setOpened(index)}>
                                            <div className="font-medium text-slate-700 dark:text-white max-w-[80%]">Seção {index + 1}: {item.name}</div>
                                            <div className="cursor-pointer dark:text-white" onClick={() => setOpened(index)}>{opened == index ? <ChevronUp /> : <ChevronDown />}</div>
                                        </div>
                                        <div className={`overflow-auto transition-all duration-500 ${opened == index ? 'max-h-[300px]' : 'max-h-[0px] overflow-hidden'}`}>
                                            <div className="p-4 px-6 mb-3 space-y-3">
                                                {item.videos.map((video, i) =>
                                                    (<div key={`video_${i}`} onClick={() => changePlayer(video.path)} className="underline cursor-pointer dark:text-white font-medium">Aula {i + 1} - {video.name}</div>)
                                                )}
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default Videos;
