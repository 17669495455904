// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%2730%27 height=%2730%27 viewBox=%270 0 40 40%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg fill=%27%23555%27 fill-rule=%27evenodd%27%3E%3Cpath d=%27M0 40L40 0H20L0 20M40 40V20L20 40%27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./gradiente.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  */
.range-slider {
  max-width: 580px;
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.single-thumb .range-slider__range {
  border-radius: 6px;
}

/* Yellow Range Slider */
#range-slider-yellow {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) #333;
}

#range-slider-yellow .range-slider__range {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat top left;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #414141;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 16px;
}

html.dark #range-slider-yellow .range-slider__thumb {
  background: #ffffff;
  transition: transform 0.3s;
}

html.dark #range-slider-yellow {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) #9e9e9e;
}`, "",{"version":3,"sources":["webpack://./src/components/product/attributes/deps/range.css"],"names":[],"mappings":"AAAA,KAAK;AACL;EACE,gBAAgB;AAClB;;AAEA,0CAA0C;AAC1C;EACE,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB;;AAEA,wBAAwB;AACxB;EACE,wDAA8O;AAChP;;AAEA;EACE,sEAAmD;EACnD,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,2DAAiP;AACnP","sourcesContent":["/*  */\n.range-slider {\n  max-width: 580px;\n}\n\n/* Range Slider as a Single Thumb Slider */\n.single-thumb .range-slider__thumb[data-lower] {\n  width: 0;\n}\n\n.single-thumb .range-slider__range {\n  border-radius: 6px;\n}\n\n/* Yellow Range Slider */\n#range-slider-yellow {\n  background: url(\"data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\") #333;\n}\n\n#range-slider-yellow .range-slider__range {\n  background: url(./gradiente.jpg) no-repeat top left;\n  transition: height 0.3s;\n}\n\n#range-slider-yellow .range-slider__thumb {\n  background: #414141;\n  transition: transform 0.3s;\n}\n\n#range-slider-yellow .range-slider__thumb[data-active] {\n  transform: translate(-50%, -50%) scale(1.25);\n}\n\n#range-slider-yellow .range-slider__range[data-active] {\n  height: 16px;\n}\n\nhtml.dark #range-slider-yellow .range-slider__thumb {\n  background: #ffffff;\n  transition: transform 0.3s;\n}\n\nhtml.dark #range-slider-yellow {\n  background: url(\"data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\") #9e9e9e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
