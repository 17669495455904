import React from "react";

import LotsItem from "../lotsItem/LotsItem";
import Button from "../../../../components/button/Button";

const Lots = () => {
  return (
    <div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
        {[1, 2, 3, 4, 5, 6].map((item, i) => {
          return <LotsItem item={item} />;
        })}
      </div>
      <div className="mt-5 flex flex-row justify-end 2xl:hidden gap-3">
        <Button variant="transparent">Ver mais</Button>
        <Button>Emitir Relatório</Button>
      </div>
    </div>
  );
};

export default Lots;
