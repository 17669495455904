import React, { useEffect } from "react";

import { Content } from "../../../components/layout";

import { Settings } from "lucide-react";
import ThemeSwitcher from "../../../components/layout/sideMenu/themeSwitcher/ThemeSwitcher";
import FormInput from "../../../components/forms/formInput/FormInput";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../extras/providers/AuthContext";
import Button from "../../../components/button/Button";
import { useUpdateUser } from "../../../extras/services/queries";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import configsUserSchema from "../../../extras/schemas/configs-user.schema";

const Configs = () => {
  const { user } = useAuth();
  const { mutate } = useUpdateUser();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(configsUserSchema),
  });
  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: (res) => {
        if (res.status == 200)
          toast.success("Usuário atualizado com sucesso!");
        else
          toast.error("Erro ao atualizar usuário");
      }
    })
  }


  useEffect(() => {
    setValue('email', user?.email);
  }, [user])
  return (
    <Content title="Configurações" icon={<Settings />}>
      <div className="p-5 flex flex-row justify-center">
        <div className="md:mt-10 rounded-lg bg-white dark:bg-slate-900 w-full max-w-[550px] shadow-sm">
          <ul>
            <li className="p-5 flex flex-row justify-between w-full">
              <span className="text-gray-500 dark:text-white inline-block ">
                Meu perfil
              </span>
            </li>
          </ul>
          <form onSubmit={handleSubmit(onSubmit)} className="p-5">
            <FormInput
              label="E-mail"
              placeholder="Insira seu e-mail"
              name="email"
              required
              register={register}
              error={errors?.email} />
            <FormInput
              label="Senha"
              placeholder="*****"
              type="password"
              name="password"
              required
              register={register}
              error={errors?.password} />
            <div className="float-right p-2 py-4">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </div>
      </div>
    </Content>
  );
};

export default Configs;
