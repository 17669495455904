import { useMutation, useQuery } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import { useHttp } from "../hooks/useHttp";
import { useAuth } from "../providers/AuthContext";
import { buildParams } from "../utils/utils";

export const useLogin = () => {
  const request = useHttp();

  const { setUser } = useAuth();

  const mutation = useMutation({
    mutationFn: data => request({ url: "/login", method: "post", data: data }),
    onSuccess: (response) => {
      if (response.status === 200) {
        setUser({
          id: jwtDecode(response.data.token).id,
          name: jwtDecode(response.data.token).name,
          email: jwtDecode(response.data.token).email,
          token: response.data.token
        });
      }
    },
  });

  return mutation;
}

// cadastro
export const useRegister = () => {
  const request = useHttp();

  const { setUser } = useAuth();

  const mutation = useMutation({
    mutationFn: data => request({ url: "/register", method: "post", data: data }),
    onSuccess: (response) => {
      if (response.status === 200) {
        setUser({
          id: jwtDecode(response.data.token).id,
          name: jwtDecode(response.data.token).name,
          email: jwtDecode(response.data.token).email,
          token: response.data.token
        });
      }
    }
  });

  return mutation;
}

export const useDealershipInfo = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["dealership-info"], queryFn: () => request({ url: "/dealership", method: "GET" }), refetchOnWindowFocus: false });

  return result;
}

export const useDealershipInfoMutate = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership`, method: "GET" })
  });

  return mutation;
}

export const useInfoUser = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/user`, method: "GET" })
  });

  return mutation;
}

export const useUpdateUser = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/user/update`, method: "GET" })
  });

  return mutation;
}

export const useListBranchs = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/branch?${buildParams(data)}`, method: "GET" })
  });

  return mutation;
}

export const useDealershipAuction = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["dealership-auction"], queryFn: () => request({ url: "/dealership/data/auction", method: "GET" }), refetchOnWindowFocus: false });

  return result;
}

export const useDealershipStock = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["dealership-stock"], queryFn: () => request({ url: "/dealership/data/stock", method: "GET" }), refetchOnWindowFocus: false });

  return result;
}

export const useDealershipSold = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/data/sold?${buildParams(data)}`, method: "GET" })
  });

  return mutation;
}

export const useProductAuction = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/auction?${buildParams(data)}`, method: "GET" })
  });

  return mutation;
}


export const useCreateBranch = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: "/dealership/branch/create", method: "post", data: data })
  });

  return mutation;
}

export const useUpdateBranch = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/branch/${data.branch_id}/update`, method: "post", data: data })
  });

  return mutation;
}

export const useGetBranch = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/branch/${data.branch_id}`, method: "GET" })
  });

  return mutation;
}



export const useListUsers = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/user?${buildParams(data)}`, method: "GET" })
  });

  return mutation;
}

export const useCreateUser = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: "/dealership/user/create", method: "post", data: data })
  });

  return mutation;
}

export const useUpdateDealershipUser = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/user/${data.user_id}/update`, method: "post", data: data })
  });

  return mutation;
}

export const useGetUser = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/dealership/user/${data.user_id}`, method: "GET" })
  });

  return mutation;
}

export const useStates = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["states"], queryFn: () => request({ url: "/location/state", method: "GET" }), refetchOnWindowFocus: false });

  return result;
}

export const useCities = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: (stateId = 1) => request({ url: "/location/city?state=" + stateId || "", method: "GET", data: {} })
  });

  return mutation;
}

export const useCreateEquipment = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: "/product/create", method: "post", data: data })
  });

  return mutation;
}

export const useSector = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["sectors"], queryFn: () => request({ url: "/sector", method: "GET" }), refetchOnWindowFocus: false });

  return result;
}

export const useCategory = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: (id) => request({ url: "/category?sector=" + id, method: "GET" })
  });

  return mutation;
}

export const useCategoryAttributes = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: (id) => request({ url: `/category/${id}/attribute`, method: "GET" })
  });

  return mutation;
}

export const useManufacturer = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: (id) => request({ url: "/manufacturer?category=" + id, method: "GET" })
  });

  return mutation;
}

export const useModel = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: (id) => request({ url: "/model?manufacturer=" + id, method: "GET" })
  });

  return mutation;
}

export const useProduct = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product?${buildParams(data)}`, method: "GET" })
  });

  return mutation;
}

export const useInfoProduct = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.id}`, method: "GET" })
  });

  return mutation;
}

export const useUpdateProduct = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.id}/update`, method: "PUT", data: data })
  });

  return mutation;
}


export const useDeleteProduct = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.id}/delete`, method: "DELETE" })
  });

  return mutation;
}

export const useSoldProduct = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.id}/sold`, method: "PUT", data: data })
  });

  return mutation;
}

export const useDeleteImage = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.product_id}/image/remove`, method: "DELETE", data: data })
  });

  return mutation;
}

export const useMainImage = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.product_id}/image/main`, method: "PUT", data: data })
  });

  return mutation;
}

export const useAddImage = () => {
  const request = useHttp();

  const mutation = useMutation({
    mutationFn: data => request({ url: `/product/${data.product_id}/image/add`, method: "DELETE", data: data })
  });

  return mutation;
}

export const useVideosTutoriais = () => {
  const request = useHttp();

  const result = useQuery({ queryKey: ["tutorial"], queryFn: () => request({ url: "/tutorial/videos", method: "GET" }), refetchOnWindowFocus: false });

  return result;

}
